import { makeStyles } from "@fluentui/react-components";

export const usePersonalMenuStyles = makeStyles({
    personalMenu: {
        marginRight: "1em"
    },
    personalMenuDropdown: {
        backgroundColor: "rgb(11, 55, 84)"
    },
    menuItem: {
        backgroundColor: "rgb(11, 55, 84)",
        color: "white"
    },
    avatarImage: {
        cursor: "pointer"
    },
});