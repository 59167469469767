export const validateRequiredOperations = (allowedOperations: string[], requiredOperations: string[], validateAll: boolean = true) => {
    if (validateAll) {
        return requiredOperations.every((el) => {
            return allowedOperations.includes(el);
        });
    }
    else {
        return requiredOperations.some((el) => {
            return allowedOperations.includes(el);
        });
    }
};