import { makeStyles, shorthands } from "@fluentui/react-components";

export const usePageHeaderStyles = makeStyles({
    header: {
        ...shorthands.gridArea('header'),
        ...shorthands.padding(0, '16px'),
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgb(11, 55, 84)"
    },
    headerMenu: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
        color: "white",
        cursor: "pointer",
        borderRadius: "50%",
    },
    headerSplitLeft: {
        marginLeft: "auto"
    },
    headerSplitRight: {
        marginRight: "auto"
    },
    headerEnv: {
        fontWeight: "bold",
        fontSize: "18px",
        color: "red"
    },
    headerVersion: {
        color: "red",
        fontSize: "12px"
    },
    headerSpinner: {
        ...shorthands.margin(0, "auto"),
        "> label": {
            color: "white"
        }
    }
});