import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { acquireApiAccessToken } from '../auth/identity';
import config from '../config';

export const emptyEdgeToolApi = createApi({
    tagTypes: ['ERROR'],
    reducerPath: 'edgetoolApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => {
            return acquireApiAccessToken(null)
                .then((token) => {
                    headers.set('Authorization', `Bearer ${token}`);
                    return headers;
                })
        },
    }),
    endpoints: (build) => ({
        refetchErroredQueries: build.mutation<null, void>({
            queryFn: () => ({ data: null }),
            invalidatesTags: ['ERROR'],
        }),
    }),
});
