import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const usePageLayoutStyles = makeStyles({
    gridContainer: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "50px 1fr 0px",
        gridTemplateAreas: "'header' 'main'",
        height: "100vh"
    },
    main: {
        ...shorthands.gridArea('main'),
        overflowX: "hidden",
        overflowY: "auto",
        '@media only screen and (min-width: 46.875em)': {
            marginLeft: "245px"
        }
    },
    mainExpanded: {
        '@media only screen and (min-width: 46.875em)': {
            transform: "translateX(0)",
            marginLeft: "45px"
        }
    },
    sideNav: {
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        top: "50px",
        width: "240px",
        overflowY: "auto",
        transform: "translateX(-245px)",
        boxShadow: ["0 2px 2px 0 rgba(0, 0, 0, 0.16)", "0 0 0 1px rgba(0, 0, 0, 0.08)"],
        zIndex: 2,
        backgroundColor: "rgb(239, 246, 252)",
        color: "rgb(50, 49, 48)",
        '@media only screen and (min-width: 46.875em)': {
            position: "relative",
            ...shorthands.gridArea('main'),
            height: "100%",
            top: 0,
            transform: "translateX(0)",
        }
    },
    sideNavCollapsed: {
        transform: "translateX(0)",
        '@media only screen and (min-width: 46.875em)': {
            width: "42px"
        }
    },
    jsonBox: {
        ...shorthands.overflow("auto"),
        position: "relative",
        width: "85%",
        height: "75vh",
        ...shorthands.margin(0, 0, 0, "1em"),
        backgroundColor: "#272822",
        '@media only screen and (max-width: 750px)': {
            ...shorthands.margin("2em", 0, 0, "2em"),
            height: "70vh"
        }
    },
    jsonBoxHorizontal: {
        position: "absolute",
        height: "80%"
    },
    listContentContainer: {
        position: "relative",
        height: "98%"
    },
    loadingSpinner: {
        ...shorthands.margin("1em")
    },
    pageTitleSection: {
        maxHeight: "1em",
        display: "flex",
        alignItems: "center",
        ...shorthands.margin("1em"),
        '@media only screen and (max-width: 750px)': {
            maxHeight: "2em"
        }
    },
    pageTopSection: {
        ...shorthands.margin("2em", 0)
    },
    noTopSection: {
        ...shorthands.margin(0)
    },
    pageMainContentSection: {
        position: "relative",
        ...shorthands.margin("2em", 0)
    },
    pageBottomSection: {
        maxHeight: "2em",
        ...shorthands.margin("1em")
    },
    title: {
        fontSize: "1.2em",
        '@media only screen and (max-width: 750px)': {
            fontSize: "1em"
        }
    },
    backIcon: {
        marginRight: ".7em",
        cursor: "pointer"
    },
    dataGrid: {
        overflow: "auto",
        position: "relative",
        width: "100%",
        display: "grid"
    },
    scrollDataGrid: {
        overflowY: "hidden",
        position: "relative",
        width: "100%",
        display: "grid",
        margin: "auto",
    },
    scrollWrapper: {
        overflowY: "auto"
    },
    returnButton: {
        textAlign: "right",
        ...shorthands.margin(0, "1em", "1em", 0),
        display: "inline-block",
        '@media only screen and (max-width: 750px)': {
            display: "grid",
            width: "100%",
            float: "left",
            ...shorthands.margin("1em", 0, "1em", 0),
        }
    },
    buttonBlock: {
        display: "flex",
        "> button": {
            ...shorthands.margin(0, "1em")
        },
        alignItems: "center",
        '@media only screen and (max-width: 750px)': {
            display: "grid",
            ...shorthands.margin("1em"),
            "> button": {
                ...shorthands.margin(".5em", 0)
            }
        }
    },
    bottomButtons: {
        ...shorthands.margin("2em", "2em", "2em", 0),
        position: "relative",
        bottom: "2.5%",
        '@media only screen and (max-width: 750px)': {
            ...shorthands.margin(0),
        }
    },
    bottomRightButton: {
        float: "right",
        right: "2em",
        '@media only screen and (max-width: 750px)': {
            width: "100%",
            right: 0,
            display: "grid"
        }
    },
    mobileButton: {
        '@media only screen and (max-width: 750px)': {
            display: "grid",
            ...shorthands.margin(0, 0, "1em", 0),
            width: "100%"
        }
    },
    content: {
        ...shorthands.margin("1em")
    },
    clearCacheButton: {
        display: "block",
        marginTop: "2em"
    },
    redButton: {
        backgroundColor: `${tokens.colorPaletteDarkOrangeBackground3}`,
        ":hover": {
            backgroundColor: `${tokens.colorPaletteDarkOrangeForeground1}`
        }
    },
    greenButton: {
        backgroundColor: `${tokens.colorPaletteLightGreenBackground3}`,
        ":hover": {
            backgroundColor: `${tokens.colorPaletteLightGreenForeground1}`
        }
    }
});