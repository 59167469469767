import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface FeatureState {
  features?: string[];
  isLoadingEnabledFeatures: boolean;
}

const initialState: FeatureState = {
  features: undefined,
  isLoadingEnabledFeatures: false
};

export type FeaturePayload = {
  features: string[] | undefined;
  isLoadingEnabledFeatures: boolean;
}

export const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setFeatures: (state, action: PayloadAction<FeaturePayload>) => {
      state.features = action.payload.features;
      state.isLoadingEnabledFeatures = action.payload.isLoadingEnabledFeatures
    }
  }
});

export const { setFeatures } = featureSlice.actions;
export const selectFeatureInfo = (state: RootState) => state.features;
export default featureSlice.reducer;
