import React from 'react';
import { Spinner } from '@fluentui/react-components';
import { selectAccountInfo } from '../../app/accountSlice';
import { useAppSelector } from '../../app/hooks';
import { useMainStyles } from '../../MainStyles';

export const NotLoggedIn = ({ children }: { children: React.ReactNode }) => {
    const accountSlice = useAppSelector(selectAccountInfo);
    const styles = useMainStyles();

    if (accountSlice.status === 'loggedIn') {
        return null;
    }

    if (accountSlice.status === 'loading') {
        return <Spinner className={styles.centerElement} />;
    }

    return (
        <>
            {children}
        </>
    );
}
