import { SignOutButton } from '../auth/components/SignOutButton';
import { selectAccountInfo } from '../app/accountSlice';
import { useAppSelector } from '../app/hooks';
import { useMainStyles } from '../MainStyles';

export const NoValidRolesPage = () => {
    const accountSlice = useAppSelector(selectAccountInfo);
    const styles = useMainStyles();

    return (
        <div className={styles.centerElementWithText} data-testid={"noValidRoles"}>
            <p>You are logged in as {accountSlice.graphData?.displayName} &lt;{accountSlice.graphData?.mail}&gt;
                but you do not have rights to use this application. Please request rights or login as a different user.</p>
            <SignOutButton />
        </div>
    );
}
