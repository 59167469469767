import { Spinner, mergeClasses } from '@fluentui/react-components';
import { CollapsibleSideNav } from './CollapsibleSideNav';
import { PageHeader } from './PageHeader';
import { PageMessageBar } from '../components/PageMessageBar/PageMessageBar';
import React from 'react';
import { useIsMobile } from '../hooks/useIsMobile';
import { usePageLayoutStyles } from './PageLayoutStyles';
import { useSideNavExpand } from '../hooks/useSideNavExpand';

type PageLayoutProps = {
    children: React.ReactNode;
    isLoadingEnabledFeatures: boolean;
    isLoadingAllowedOperations: boolean;
}

export const PageLayout = ({ children, isLoadingEnabledFeatures, isLoadingAllowedOperations }: PageLayoutProps) => {
    const [isOpen, setOpen] = useSideNavExpand(false);
    const isMobile = useIsMobile();

    const pageLayoutStyles = usePageLayoutStyles();

    const mainClass = mergeClasses(pageLayoutStyles.main, isOpen && pageLayoutStyles.mainExpanded);

    return (
        <div className={pageLayoutStyles.gridContainer}>
            <PageHeader toggleSideBarOpen={setOpen} isLoadingEnabledFeatures={isLoadingEnabledFeatures} />
            
            {isLoadingAllowedOperations || isLoadingEnabledFeatures ?
                <Spinner
                    size="small"
                    label="Please wait, loading required resources..."
                    className={pageLayoutStyles.loadingSpinner}
                    data-testid="resourceSpinner"
                    labelPosition="below"
                /> :
                <>
                    <CollapsibleSideNav
                        isOpen={isOpen}
                        mobile={isMobile}
                        setOpen={setOpen}
                    />
                    <main id={'main'} className={mainClass}
                        onClick={() => {
                            if (isOpen && isMobile)
                                setOpen();
                        }}
                    >
                        <PageMessageBar />
                        {children}
                    </main>
                </>
            }
        </div>
    );
}
