import React, { MouseEventHandler } from 'react';
import { Spinner, Text, mergeClasses } from '@fluentui/react-components';
import { Navigation20Regular } from '@fluentui/react-icons';
import { PersonalMenu } from '../components/PersonalMenu/PersonalMenu';
import config from '../config';
import { selectIsLoadingListData } from '../app/loaderSlice';
import { useAppSelector } from '../app/hooks';
import { usePageHeaderStyles } from './PageHeaderStyles';
import versionInfo from '../app/versionInfo.macro';

type IHeaderProps = {
    toggleSideBarOpen: () => void;
    isLoadingEnabledFeatures: boolean;
}

export const PageHeader = (props: IHeaderProps) => {
    const handler: MouseEventHandler<SVGElement> = (e: any) => {
        if (!props.isLoadingEnabledFeatures) {
            e.preventDefault();
            props.toggleSideBarOpen();
        }
    }

    const isLoadingListData = useAppSelector(selectIsLoadingListData).isListLoadingData;
    const pageHeaderStyles = usePageHeaderStyles();

    return (
        <div className={pageHeaderStyles.header} >
            <>
                <div className={pageHeaderStyles.headerMenu}>
                    <Navigation20Regular onClick={handler} />
                </div>
                <div className={mergeClasses(pageHeaderStyles.headerSplitLeft, pageHeaderStyles.headerSplitRight)}></div>
                {
                    isLoadingListData &&
                    <Spinner size="tiny" label={"Loading..."} className={pageHeaderStyles.headerSpinner} data-testid={"headerSpinner"}/>
                }
            </>
            {config.environment !== "Production" ?
                <>
                    <div className={pageHeaderStyles.headerSplitLeft}>
                        <Text className={pageHeaderStyles.headerEnv}>{config.environment?.toUpperCase()}&nbsp;&nbsp;</Text>
                        {React.createElement("br")}
                        <Text className={pageHeaderStyles.headerVersion}>{versionInfo().SemVer}&nbsp;&nbsp;</Text>
                    </div>
                    <PersonalMenu />
                </> :
                <div className={pageHeaderStyles.headerSplitLeft}><PersonalMenu /></div>
            }
        </div>
    )
};
