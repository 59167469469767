import { msalInstance } from './msal';
import { apiSilentLoginRequest, graphSilentLoginRequest } from './authConfig';
import { AccountInfo } from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';

interface ITokenRoles {
    roles: string[] | undefined,
}

export const acquireApiAccessToken = async (account: AccountInfo | null) => {
    if (!account) {
        account = msalInstance.getActiveAccount();
    }

    if (!account) {
        return null;
    }

    const authResult = await msalInstance.acquireTokenSilent({
        ...apiSilentLoginRequest,
        account
    });

    return authResult.accessToken;
};

export const determineRolesFromApiAccessToken = (apiAccessToken: string) => {
    var decoded: ITokenRoles = jwt_decode(apiAccessToken);
    if (!decoded.roles || decoded.roles.length === 0) {
        return null;
    }
    return decoded.roles;
}

export const acquireGraphAccessToken = async (account: AccountInfo | null) => {
    if (!account) {
        account = msalInstance.getActiveAccount();
    }

    if (!account) {
        return null;
    }

    const authResult = await msalInstance.acquireTokenSilent({
        ...graphSilentLoginRequest,
        account
    });

    return authResult.accessToken;
};

export type IGraphData = {
    id?: string;
    userPrincipalName?: string;
    displayName?: string;
    mail?: string;
}

export async function callMsGraph(accessToken: string): Promise<IGraphData> {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch("https://graph.microsoft.com/v1.0/me", options)
        .then(response => response.json());
}
