import React, { useEffect } from "react";
import { NoValidRolesPage } from "../../pages/NoValidRolesPage";
import { setAllowedOperations } from "../../app/allowedOperationSlice";
import { showMessageBar } from "../../app/messageBarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useGetAuthorizationAllowedOperationsQuery } from "../../services/edgeToolApi";

export const AuthorizedOperationProvider = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();
    const { data, isLoading, isError } = useGetAuthorizationAllowedOperationsQuery();

    useEffect(() => {
        dispatch(setAllowedOperations(isLoading || isError ?
            { allowedOperations: undefined, isOperationsLoading: isError ? false : isLoading } :
            { allowedOperations: data, isOperationsLoading: isLoading }));

        if (isError) {
            dispatch(showMessageBar({ message: "Could not determine allowed operations for logged in user. Some operations may be impacted.", type: "error" }));
        }
    }, [dispatch, isLoading, isError, data]);

    if(!isLoading && data && data.length === 0) {
        return <NoValidRolesPage />;
    }

    return <>{children}</>;
};
