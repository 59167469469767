import { makeStaticStyles, makeStyles } from "@fluentui/react-components";

export const useMainStyles = makeStyles({
  centerElement: {
    display: "grid",
    height: "100%",
    position: "relative",
    left: "50%",
    width: "fit-content",
    top: "50vh"
  },
  centerElementWithText: {
    width: "40%",
    display: "grid",
    height: "100%",
    position: "relative",
    left: "30%",
    top: "45vh"
  }
});

export const useStaticStyles = makeStaticStyles([
  'html { font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif; }',
]);