import { configureStore, ThunkAction, Action, PreloadedState, combineReducers } from '@reduxjs/toolkit';
import { enhancedEdgeToolApi } from '../services/enhancedEdgeToolApi';
import { accountSlice } from './accountSlice';
import { featureSlice } from './featureSlice';
import { iotHubSlice } from './iotHubSlice';
import { messageBarSlice } from './messageBarSlice';
import { allowedOperationSlice } from './allowedOperationSlice';
import { listLoaderSlice } from './loaderSlice';

const rootReducer = combineReducers({
    account: accountSlice.reducer,
    messageBar: messageBarSlice.reducer,
    features: featureSlice.reducer,
    iotHubId: iotHubSlice.reducer,
    allowedOperations: allowedOperationSlice.reducer,
    isListLoadingData: listLoaderSlice.reducer,
    [enhancedEdgeToolApi.reducerPath]: enhancedEdgeToolApi.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(enhancedEdgeToolApi.middleware),
        preloadedState,
    });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
