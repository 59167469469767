import { Avatar, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { accountSlice, selectAccountInfo } from "../../app/accountSlice";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { acquireApiAccessToken } from "../../auth/identity";
import config from '../../config';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { usePersonalMenuStyles } from './PersonalMenuStyles';
import versionInfo from "../../app/versionInfo.macro";

export const PersonalMenu = () => {
    const accountInfo = useAppSelector(selectAccountInfo);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const styles = usePersonalMenuStyles();

    const { instance } = useMsal();
    const signOut = () => {
        dispatch(accountSlice.actions.signout());
        instance.logoutRedirect();
    }

    const downloadSwagger = async () => {
        const token = await acquireApiAccessToken(null);
        let rawResult = await fetch(`${config.apiUrl}/swagger/v1/swagger.json`,
            {
                mode: 'cors',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
        const blobResult = await rawResult.blob();
        const objectURL = URL.createObjectURL(blobResult);
        window.open(objectURL, '_blank');
        URL.revokeObjectURL(objectURL);
    }

    const openSwaggerAPI = async () => {
        window.open(`${config.apiUrl}/swagger/index.html`, '_blank', 'noreferrer');
    }

    return (
        <div className={styles.personalMenu}>
            <Menu positioning="below-end">
                <MenuTrigger>
                    <Avatar
                        name={accountInfo.graphData?.displayName ?? ""}
                        className={styles.avatarImage}
                        color="brand"
                        size={40}
                    />
                </MenuTrigger>
                <MenuPopover className={styles.personalMenuDropdown}>
                    <MenuList >
                        <MenuItem key="profile"
                            onClick={() => navigate('/profile')}
                            className={styles.menuItem}
                        >
                            Profile
                        </MenuItem>
                        <MenuItem key="signout"
                            onClick={() => signOut()}
                            className={styles.menuItem}
                        >
                            Sign Out
                        </MenuItem>
                        <MenuItem key="version"
                            onClick={() => downloadSwagger()}
                            className={styles.menuItem}
                        >
                            {`Version: ${versionInfo().MajorMinorPatch}`}
                        </MenuItem>
                        <MenuItem key="api"
                            onClick={() => openSwaggerAPI()}
                            className={styles.menuItem}
                        >
                            Show OpenAPI UI
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    );
}
