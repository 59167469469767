import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IotHubState {
    iotHubId: string;
}

const initialState: IotHubState = {
    iotHubId: ""
}

export type IotHubIdPayload = {
    iotHubId: string;
}

export const iotHubSlice = createSlice({
    name: 'iotHubId',
    initialState,
    reducers: {
        changeIotHubId: (state, action: PayloadAction<IotHubIdPayload>) => {
            state.iotHubId = action.payload.iotHubId;
        },
    },
})

export const { changeIotHubId } = iotHubSlice.actions;
export const selectIotHubIdState = (state: RootState) => state.iotHubId;
export default iotHubSlice.reducer;
