import { Button } from "@fluentui/react-components";
import { redirectLoginRequest } from "../authConfig";
import { showMessageBar } from "../../app/messageBarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useMsal } from "@azure/msal-react";

export const SignInButton = () => {
    const { instance } = useMsal();
    const dispatch = useAppDispatch();

    const handleLogin = () => {
        instance.loginRedirect(redirectLoginRequest).catch(e => {
            dispatch(showMessageBar({ message: `Could not redirect to the login server: ${e}`, type: "error" }));
        });
    }

    return (
        <Button
            appearance="primary"
            onClick={handleLogin}
        >
            Sign in
        </Button>
    )
}
