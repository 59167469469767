import { INavLink, INavLinkGroup, INavStyles, Nav } from '@fluentui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mergeClasses } from '@fluentui/react-components';
import { selectAllowedOperations } from '../app/allowedOperationSlice';
import { selectFeatureInfo } from '../app/featureSlice';
import { tokens } from '@fluentui/react-components';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { usePageLayoutStyles } from './PageLayoutStyles';
import { selectIsLoadingListData, setIsListLoadingData } from '../app/loaderSlice';

const navigationStyles: Partial<INavStyles> = {
    root: {
        overflowY: 'auto',
    },
};

const links = (mobile: boolean, setOpen: () => void, features: string[], allowedOperations: string[], pathname: string): INavLinkGroup[] => {
    const getLinkStyle = (url: string) => {
        const path = pathname.split('/');
        let isSelected = path[1] === url;

        // to cover case when navigating to edge request review page
        // or to deployments page from dashboard
        if ((path[1].includes('edgerequest') && url === "edgerequests") || (path[1].includes('deployment') && url === "deployments"))
            isSelected = true;

        return {
            backgroundColor: isSelected ? `${tokens.colorBrandBackgroundInvertedSelected}` : 'inherit'
        };
    };

    let lks: INavLink[] = [
        {
            name: 'Dashboard',
            key: 'dashboard',
            url: '/',
            iconProps: {
                iconName: 'News',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle(''),
            resourceOperation: []
        },
        {
            name: 'Administration',
            key: 'administration',
            url: '/administration',
            iconProps: {
                iconName: 'Admin',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle('administration'),
            resourceOperation: [
                "Vanderlande.EdgeMan/roleAssignment/read",
                "Vanderlande.EdgeMan/admin/cache/delete",
                "Vanderlande.EdgeMan/roleDefinition/read"
            ]
        },
        {
            name: 'Edge List',
            key: 'edges',
            url: '/edges',
            iconProps: {
                iconName: 'BulletedList2',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle('edges'),
            resourceOperation: ["Vanderlande.EdgeMan/hub/edgeList/read"]
        },
        {
            name: 'Edge Requests',
            key: 'edgeRequests',
            url: '/edgerequests',
            iconProps: {
                iconName: 'ShopServer',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle('edgerequests'),
            resourceOperation: ["Vanderlande.EdgeMan/edgeRequest/read"]
        },
        {
            name: 'Deployments',
            key: 'deployments',
            url: '/deployments',
            iconProps: {
                iconName: 'ProductList',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle('deployments'),
            resourceOperation: ["Vanderlande.EdgeMan/deployment/read"]
        },
        {
            name: 'Module Definitions',
            key: 'moduleDefinitions',
            url: '/moduledefinitions',
            iconProps: {
                iconName: 'BulletedList2',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle('moduledefinitions'),
            resourceOperation: ["Vanderlande.EdgeMan/moduleDefinition/read"]
        },
        {
            name: 'Workload Definitions',
            key: 'workloadDefinitions',
            url: '/workloaddefinitions',
            iconProps: {
                iconName: 'BulletedList2',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle('workloaddefinitions'),
            resourceOperation: ["Vanderlande.EdgeMan/workloadDefinition/read"]
        },
        {
            name: 'Site Overview',
            key: 'siteOverview',
            url: '/site',
            iconProps: {
                iconName: 'ShopServer',
                styles: {
                    root: {
                        fontSize: 20,
                        color: '#106ebe',
                    }
                }
            },
            onClick: mobile ? (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => setOpen() : undefined,
            style: getLinkStyle('site'),
            resourceOperation: ["Vanderlande.EdgeMan/hub/edgeList/read"]
        }
    ];

    lks = lks.filter(n => (n.feature === undefined || features.includes(n.feature)));
    lks = lks.filter(n => (n.resourceOperation === undefined
        || n.resourceOperation.length === 0
        || n.resourceOperation.filter((operation: string) => allowedOperations?.includes(operation)).length !== 0));

    return (
        [
            {
                links: lks
            },
        ]
    ) as INavLinkGroup[];
}

type ICollapsibleSideNavProps = {
    isOpen: boolean,
    mobile: boolean,
    setOpen: () => void
}

// TODO: Add tests for routing capabilities, see
//   https://testing-library.com/docs/example-react-router
//   https://github.com/remix-run/react-router/issues/7811
// for inspiration

export const CollapsibleSideNav = (props: ICollapsibleSideNavProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const featureInfo = useAppSelector(selectFeatureInfo);
    const isLoadingListData = useAppSelector(selectIsLoadingListData).isListLoadingData;
    const allowedOperationsInfo = useAppSelector(selectAllowedOperations);
    const pageLayoutStyles = usePageLayoutStyles();

    const { pathname } = useLocation();
    const { mobile, setOpen } = props;

    return (
        <aside className={mergeClasses(pageLayoutStyles.sideNav, props.isOpen && pageLayoutStyles.sideNavCollapsed)}>
            <Nav
                onLinkClick={(event, element) => {
                    event?.preventDefault();
                    if(isLoadingListData)
                        dispatch(setIsListLoadingData({isListLoadingData: false}));
                    navigate(element!.url);
                }}
                groups={links(mobile, setOpen, featureInfo.features ?? [], allowedOperationsInfo.allowedOperations ?? [], pathname)}
                selectedKey='key1'
                styles={navigationStyles}
            />
        </aside>
    );
}
