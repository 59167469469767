import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MessageBarIntent } from '@fluentui/react-components';
import { RootState } from './store';

interface MessageBarState {
    visible: boolean;
    message: string;
    type: MessageBarIntent;
}

const initialState: MessageBarState = {
    visible: false,
    message: "",
    type: "info"
}

export type MessageBarPayload = {
    message: string;
    type: MessageBarIntent;
}

export const messageBarSlice = createSlice({
    name: 'messageBar',
    initialState,
    reducers: {
        showMessageBar: (state, action: PayloadAction<MessageBarPayload>) => {
            state.visible = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        hideMessageBar: (state) => {
            state.visible = false;
        },
    },
})

// Action creators are generated for each case reducer function
export const { showMessageBar, hideMessageBar } = messageBarSlice.actions;
export const selectMessageBarState = (state: RootState) => state.messageBar;

export default messageBarSlice.reducer;
