import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { AccountInfo } from '@azure/msal-browser';
import { acquireApiAccessToken, acquireGraphAccessToken, callMsGraph, determineRolesFromApiAccessToken, IGraphData } from '../auth/identity';

export interface SignInState {
  status: 'anonymous' | 'loading' | 'loggedIn';
  roles?: string[];
  graphData?: IGraphData;
}

const initialState: SignInState = {
  status: 'anonymous',
  roles: undefined,
  graphData: undefined,
};

export const signInAction = createAsyncThunk(
  'account/signin',
  async (account: AccountInfo, { rejectWithValue }) => {
    const apiAccessToken = await acquireApiAccessToken(account);
    if (apiAccessToken == null)
      return rejectWithValue("invalid API access token");
    const roles = determineRolesFromApiAccessToken(apiAccessToken);
    const graphAccessToken = await acquireGraphAccessToken(account);
    if (graphAccessToken == null)
      return rejectWithValue("invalid graph access token");
    const response = await callMsGraph(graphAccessToken);
    return {
      roles: roles ?? [],
      graphData: response
    };
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    signout: (state) => {
      state.status = 'anonymous';
      state.roles = undefined;
      state.graphData = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInAction.pending, (state) => {
        state.status = 'loading';
        state.roles = undefined;
        state.graphData = undefined;
      })
      .addCase(signInAction.fulfilled, (state, action) => {
        state.status = 'loggedIn';
        state.roles = action.payload.roles;
        state.graphData = action.payload.graphData;
      })
      .addCase(signInAction.rejected, (state, error) => {
        state.status = 'anonymous';
        state.roles = undefined;
        state.graphData = undefined;
      });
  },
});

export const selectAccountInfo = (state: RootState) => state.account;
export default accountSlice.reducer;
