import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AllowedOperationState {
    allowedOperations?: string[];
    isOperationsLoading: boolean;
}

const initialState: AllowedOperationState = {
    allowedOperations: undefined,
    isOperationsLoading: false
};

export type AllowedOperationPayload = {
    allowedOperations: string[] | undefined;
    isOperationsLoading: boolean;
}

export const allowedOperationSlice = createSlice({
    name: 'allowedOperation',
    initialState,
    reducers: {
        setAllowedOperations: (state, action: PayloadAction<AllowedOperationPayload>) => {
            state.allowedOperations = action.payload.allowedOperations;
            state.isOperationsLoading = action.payload.isOperationsLoading
        }
    }
});

export const { setAllowedOperations } = allowedOperationSlice.actions;
export const selectAllowedOperations = (state: RootState) => state.allowedOperations;
export default allowedOperationSlice.reducer;
