import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ListLoaderState {
    isListLoadingData: boolean;
}

const initialState: ListLoaderState = {
    isListLoadingData: false
};

export type ListLoaderPayload = {
    isListLoadingData: boolean;
}

export const listLoaderSlice = createSlice({
    name: 'listLoader',
    initialState,
    reducers: {
        setIsListLoadingData: (state, action: PayloadAction<ListLoaderPayload>) => {
            state.isListLoadingData = action.payload.isListLoadingData;
        }
    }
});

export const { setIsListLoadingData } = listLoaderSlice.actions;
export const selectIsLoadingListData = (state: RootState) => state.isListLoadingData;
export default listLoaderSlice.reducer;
