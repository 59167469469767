import { LogLevel, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import config from '../config';

export const msalConfig = {
    auth: {
        clientId: config.clientId ?? "",
        authority: config.authority,
        redirectUri: config.redirectUri
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const redirectLoginRequest: RedirectRequest = {
    scopes: config.apiScopes ?? [],
    extraScopesToConsent: config.graphScopes
};

export const graphSilentLoginRequest: SilentRequest = {
    scopes: config.graphScopes ?? []
};

export const apiSilentLoginRequest: SilentRequest = {
    scopes: config.apiScopes ?? []
};
