import { Button } from "@fluentui/react-components";
import { showMessageBar } from "../../app/messageBarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {
    const { instance } = useMsal();
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        const logoutRequest = {
            account: instance.getActiveAccount(),
        };
        instance.logoutPopup(logoutRequest).catch(e => {
            dispatch(showMessageBar({ message: `Could not popup logout request: ${e}`, type: "error" }));
        });
    }

    return (
        <Button
            appearance="primary"
            onClick={handleLogout}
        >
            Sign out
        </Button>
    )
}
