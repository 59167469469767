import { useCallback, useState } from "react";

export const useSideNavExpand = (initialState: boolean = false): [boolean, () => void] => {
    // Initialize the state
    const [state, setState] = useState<boolean>(getInitialStateFromLocalStorage(initialState));
    // Define and memorize toggler function in case we pass down the component,
    // This function change the boolean value to it's opposite value
    const toggle = useCallback((): void => {
        switchLocalStorageSideNavState();
        setState(state => !state)
    }, []);

    return [state, toggle]
}

const getInitialStateFromLocalStorage = (state: boolean) => {
    const menuHidden = localStorage.getItem('hideSideBar');
    // set sideBar state from local storage for desktop
    if (window.innerWidth > 750) {
        if (menuHidden != null && menuHidden === "true") {
            localStorage.setItem('hideSideBar', JSON.stringify(true));
            return true;
        }
        localStorage.setItem('hideSideBar', JSON.stringify(false));
        return false;
    }

    return state;
};

const switchLocalStorageSideNavState = () => {
    // switch sideBar state from local storage for desktop
    if (window.innerWidth > 750) {
        const menuHidden = localStorage.getItem('hideSideBar');
        localStorage.setItem('hideSideBar', JSON.stringify((menuHidden === 'false')));
    }
}
