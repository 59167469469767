import { SignInButton } from '../auth/components/SignInButton';
import { useMainStyles } from '../MainStyles';

export const LoginPage = () => {
    const styles = useMainStyles();

    return (
        <div className={styles.centerElement}>
            <SignInButton />
        </div>
    );
}
