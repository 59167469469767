import { Navigate, Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAllowedOperations } from "../../app/allowedOperationSlice";
import { showMessageBar } from "../../app/messageBarSlice";
import { useEffect } from "react";
import { validateRequiredOperations } from "../../app/validateRequiredOperations";

export enum ValidateOperations {
    All,
    Any
}

export const ProtectedPage = ({
    resourceOperations,
    redirectPath = '/',
    children,
    validate = ValidateOperations.Any
}: {
    resourceOperations: string[],
    redirectPath?: string,
    children?: React.ReactNode,
    validate?: ValidateOperations,
}) => {
    const allowedOperations = useAppSelector(selectAllowedOperations)?.allowedOperations ?? [];

    const dispatch = useAppDispatch();
    const validateAll = validate === ValidateOperations.All ? true : false;
    const isAccessAllowed = validateRequiredOperations(allowedOperations, resourceOperations, validateAll);

    useEffect(() => {
        if (!isAccessAllowed) {
            const messageInfo = validateAll ? "all" : "any";
            const message = `You are being redirected to dashboard since you don't have required permissions to access the page. Your assigned role(s) needs to have ${messageInfo} of the following resource operations - ${resourceOperations.join(", ")}`;

            dispatch(showMessageBar({ message, type: "warning" }));
        }
    }, [
        isAccessAllowed, 
        dispatch, 
        validateAll, 
        resourceOperations
    ]);

    if(!isAccessAllowed)
        return <Navigate to={redirectPath} replace />;

    return children ?
        <>{children}</> : <Outlet />;
};
