import { emptyEdgeToolApi as api } from "./emptyEdgeToolApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteAdminCache: build.mutation<
      DeleteAdminCacheApiResponse,
      DeleteAdminCacheApiArg
    >({
      query: () => ({ url: `/admin/cache`, method: "DELETE" }),
    }),
    getAdminUsers: build.query<GetAdminUsersApiResponse, GetAdminUsersApiArg>({
      query: (queryArg) => ({
        url: `/admin/users`,
        params: { searchText: queryArg.searchText },
      }),
    }),
    getAuditEvent: build.query<GetAuditEventApiResponse, GetAuditEventApiArg>({
      query: (queryArg) => ({
        url: `/audit-event`,
        params: { page: queryArg.page },
      }),
    }),
    getAuthorizationAllowedOperations: build.query<
      GetAuthorizationAllowedOperationsApiResponse,
      GetAuthorizationAllowedOperationsApiArg
    >({
      query: () => ({ url: `/authorization/allowed-operations` }),
    }),
    getConfigurationRegions: build.query<
      GetConfigurationRegionsApiResponse,
      GetConfigurationRegionsApiArg
    >({
      query: () => ({ url: `/configuration/regions` }),
    }),
    getConfigurationFeatures: build.query<
      GetConfigurationFeaturesApiResponse,
      GetConfigurationFeaturesApiArg
    >({
      query: () => ({ url: `/configuration/features` }),
    }),
    postConfigurationSendTestEmail: build.mutation<
      PostConfigurationSendTestEmailApiResponse,
      PostConfigurationSendTestEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/configuration/send-test-email`,
        method: "POST",
        params: { beforeRenewal: queryArg.beforeRenewal },
      }),
    }),
    getDeploymentSecretGlobal: build.query<
      GetDeploymentSecretGlobalApiResponse,
      GetDeploymentSecretGlobalApiArg
    >({
      query: () => ({ url: `/deployment-secret/global` }),
    }),
    getDeploymentSecretSite: build.query<
      GetDeploymentSecretSiteApiResponse,
      GetDeploymentSecretSiteApiArg
    >({
      query: (queryArg) => ({
        url: `/deployment-secret/site`,
        params: { siteId: queryArg.siteId },
      }),
    }),
    getDeploymentSecretDevice: build.query<
      GetDeploymentSecretDeviceApiResponse,
      GetDeploymentSecretDeviceApiArg
    >({
      query: (queryArg) => ({
        url: `/deployment-secret/device`,
        params: { deviceId: queryArg.deviceId },
      }),
    }),
    postDeploymentSecret: build.mutation<
      PostDeploymentSecretApiResponse,
      PostDeploymentSecretApiArg
    >({
      query: (queryArg) => ({
        url: `/deployment-secret`,
        method: "POST",
        body: queryArg.deploymentSecret,
      }),
    }),
    patchDeploymentSecret: build.mutation<
      PatchDeploymentSecretApiResponse,
      PatchDeploymentSecretApiArg
    >({
      query: (queryArg) => ({
        url: `/deployment-secret`,
        method: "PATCH",
        body: queryArg.deploymentSecret,
      }),
    }),
    deleteDeploymentSecret: build.mutation<
      DeleteDeploymentSecretApiResponse,
      DeleteDeploymentSecretApiArg
    >({
      query: (queryArg) => ({
        url: `/deployment-secret`,
        method: "DELETE",
        body: queryArg.deploymentSecret,
      }),
    }),
    getEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: build.query<
      GetEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse,
      GetEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.edgeDeploymentId}/workload/${queryArg.workloadId}`,
      }),
    }),
    postEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: build.mutation<
      PostEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse,
      PostEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.edgeDeploymentId}/workload/${queryArg.workloadId}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    patchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: build.mutation<
      PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse,
      PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.edgeDeploymentId}/workload/${queryArg.workloadId}`,
        method: "PATCH",
        body: queryArg.deploymentWorkloadInstance,
      }),
    }),
    deleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: build.mutation<
      DeleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse,
      DeleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.edgeDeploymentId}/workload/${queryArg.workloadId}`,
        method: "DELETE",
      }),
    }),
    patchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatest:
      build.mutation<
        PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatestApiResponse,
        PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatestApiArg
      >({
        query: (queryArg) => ({
          url: `/edge-deployment/${queryArg.edgeDeploymentId}/workload/${queryArg.workloadId}/update-to-latest`,
          method: "PATCH",
        }),
      }),
    getDeviceCertificateInfoByDeviceId: build.query<
      GetDeviceCertificateInfoByDeviceIdApiResponse,
      GetDeviceCertificateInfoByDeviceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/device-certificate/info/${queryArg.deviceId}`,
      }),
    }),
    getDeviceCertificateByDeviceId: build.query<
      GetDeviceCertificateByDeviceIdApiResponse,
      GetDeviceCertificateByDeviceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/device-certificate/${queryArg.deviceId}`,
      }),
    }),
    getDeviceCertificateByDeviceIdKey: build.query<
      GetDeviceCertificateByDeviceIdKeyApiResponse,
      GetDeviceCertificateByDeviceIdKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/device-certificate/${queryArg.deviceId}/key`,
      }),
    }),
    postDeviceCertificateRenew: build.mutation<
      PostDeviceCertificateRenewApiResponse,
      PostDeviceCertificateRenewApiArg
    >({
      query: (queryArg) => ({
        url: `/device-certificate/renew`,
        method: "POST",
        body: queryArg.deviceCertificateInformation,
      }),
    }),
    getDeviceCertificateIssuerchainpem: build.query<
      GetDeviceCertificateIssuerchainpemApiResponse,
      GetDeviceCertificateIssuerchainpemApiArg
    >({
      query: () => ({ url: `/device-certificate/issuerchainpem` }),
    }),
    postDeviceCertificateByCertificateNameExpiring: build.mutation<
      PostDeviceCertificateByCertificateNameExpiringApiResponse,
      PostDeviceCertificateByCertificateNameExpiringApiArg
    >({
      query: (queryArg) => ({
        url: `/device-certificate/${queryArg.certificateName}/expiring`,
        method: "POST",
      }),
    }),
    getEdgeByIotHubIdAndDeviceId: build.query<
      GetEdgeByIotHubIdAndDeviceIdApiResponse,
      GetEdgeByIotHubIdAndDeviceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge/${queryArg.iotHubId}/${queryArg.deviceId}`,
      }),
    }),
    deleteEdgeByIotHubIdAndDeviceId: build.mutation<
      DeleteEdgeByIotHubIdAndDeviceIdApiResponse,
      DeleteEdgeByIotHubIdAndDeviceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge/${queryArg.iotHubId}/${queryArg.deviceId}`,
        method: "DELETE",
      }),
    }),
    getEdgeByIotHubIdAndDeviceIdModuleId: build.query<
      GetEdgeByIotHubIdAndDeviceIdModuleIdApiResponse,
      GetEdgeByIotHubIdAndDeviceIdModuleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge/${queryArg.iotHubId}/${queryArg.deviceId}/${queryArg.moduleId}`,
      }),
    }),
    postEdgeByIotHubIdAndDeviceIdInstallCertificates: build.mutation<
      PostEdgeByIotHubIdAndDeviceIdInstallCertificatesApiResponse,
      PostEdgeByIotHubIdAndDeviceIdInstallCertificatesApiArg
    >({
      query: (queryArg) => ({
        url: `/edge/${queryArg.iotHubId}/${queryArg.deviceId}/install-certificates`,
        method: "POST",
        body: queryArg.deviceCertificateInformation,
      }),
    }),
    postEdgeByIotHubIdAndDeviceIdMoveEdgeRegistration: build.mutation<
      PostEdgeByIotHubIdAndDeviceIdMoveEdgeRegistrationApiResponse,
      PostEdgeByIotHubIdAndDeviceIdMoveEdgeRegistrationApiArg
    >({
      query: (queryArg) => ({
        url: `/edge/${queryArg.iotHubId}/${queryArg.deviceId}/move-edge-registration`,
        method: "POST",
        body: queryArg.moveRegistrationRequest,
      }),
    }),
    getEdgeDeploymentByDeploymentId: build.query<
      GetEdgeDeploymentByDeploymentIdApiResponse,
      GetEdgeDeploymentByDeploymentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.deploymentId}`,
      }),
    }),
    patchEdgeDeploymentByDeploymentId: build.mutation<
      PatchEdgeDeploymentByDeploymentIdApiResponse,
      PatchEdgeDeploymentByDeploymentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.deploymentId}`,
        method: "PATCH",
        body: queryArg.edgeDeployment,
      }),
    }),
    deleteEdgeDeploymentByDeploymentId: build.mutation<
      DeleteEdgeDeploymentByDeploymentIdApiResponse,
      DeleteEdgeDeploymentByDeploymentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.deploymentId}`,
        method: "DELETE",
      }),
    }),
    getEdgeDeploymentDraftByDeviceId: build.query<
      GetEdgeDeploymentDraftByDeviceIdApiResponse,
      GetEdgeDeploymentDraftByDeviceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/draft/${queryArg.deviceId}`,
      }),
    }),
    getEdgeDeploymentList: build.query<
      GetEdgeDeploymentListApiResponse,
      GetEdgeDeploymentListApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/list`,
        params: {
          page: queryArg.page,
          statuses: queryArg.statuses,
          deployer: queryArg.deployer,
          deviceIdSearchText: queryArg.deviceIdSearchText,
        },
      }),
    }),
    getEdgeDeploymentByEdgeDeploymentIdConfiguration: build.query<
      GetEdgeDeploymentByEdgeDeploymentIdConfigurationApiResponse,
      GetEdgeDeploymentByEdgeDeploymentIdConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.edgeDeploymentId}/configuration`,
      }),
    }),
    postEdgeDeploymentByEdgeDeploymentIdConfiguration: build.mutation<
      PostEdgeDeploymentByEdgeDeploymentIdConfigurationApiResponse,
      PostEdgeDeploymentByEdgeDeploymentIdConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.edgeDeploymentId}/configuration`,
        method: "POST",
      }),
    }),
    postEdgeDeploymentDraft: build.mutation<
      PostEdgeDeploymentDraftApiResponse,
      PostEdgeDeploymentDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/draft`,
        method: "POST",
        body: queryArg.edgeDeployment,
      }),
    }),
    postEdgeDeploymentByEdgeDeploymentIdRefreshStatus: build.mutation<
      PostEdgeDeploymentByEdgeDeploymentIdRefreshStatusApiResponse,
      PostEdgeDeploymentByEdgeDeploymentIdRefreshStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.edgeDeploymentId}/refresh-status`,
        method: "POST",
      }),
    }),
    patchEdgeDeploymentByDeploymentIdSiteVariables: build.mutation<
      PatchEdgeDeploymentByDeploymentIdSiteVariablesApiResponse,
      PatchEdgeDeploymentByDeploymentIdSiteVariablesApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-deployment/${queryArg.deploymentId}/site-variables`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getEdgeRequest: build.query<
      GetEdgeRequestApiResponse,
      GetEdgeRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request`,
        params: {
          page: queryArg.page,
          searchText: queryArg.searchText,
          requestor: queryArg.requestor,
          statuses: queryArg.statuses,
          engineerEmail: queryArg.engineerEmail,
        },
      }),
    }),
    postEdgeRequest: build.mutation<
      PostEdgeRequestApiResponse,
      PostEdgeRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request`,
        method: "POST",
        body: queryArg.edgeRequest,
      }),
    }),
    getEdgeRequestByEdgeRequestId: build.query<
      GetEdgeRequestByEdgeRequestIdApiResponse,
      GetEdgeRequestByEdgeRequestIdApiArg
    >({
      query: (queryArg) => ({ url: `/edge-request/${queryArg.edgeRequestId}` }),
    }),
    patchEdgeRequestByEdgeRequestId: build.mutation<
      PatchEdgeRequestByEdgeRequestIdApiResponse,
      PatchEdgeRequestByEdgeRequestIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}`,
        method: "PATCH",
        body: queryArg.edgeRequest,
      }),
    }),
    getEdgeRequestValidateDeviceidByDeviceId: build.query<
      GetEdgeRequestValidateDeviceidByDeviceIdApiResponse,
      GetEdgeRequestValidateDeviceidByDeviceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/validate-deviceid/${queryArg.deviceId}`,
      }),
    }),
    patchEdgeRequestByEdgeRequestIdComplete: build.mutation<
      PatchEdgeRequestByEdgeRequestIdCompleteApiResponse,
      PatchEdgeRequestByEdgeRequestIdCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/complete`,
        method: "PATCH",
      }),
    }),
    patchEdgeRequestByEdgeRequestIdRedeploy: build.mutation<
      PatchEdgeRequestByEdgeRequestIdRedeployApiResponse,
      PatchEdgeRequestByEdgeRequestIdRedeployApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/redeploy`,
        method: "PATCH",
      }),
    }),
    getEdgeRequestByEdgeRequestIdConnectionString: build.query<
      GetEdgeRequestByEdgeRequestIdConnectionStringApiResponse,
      GetEdgeRequestByEdgeRequestIdConnectionStringApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/connection-string`,
      }),
    }),
    getEdgeRequestByEdgeRequestIdDownloadCertificate: build.query<
      GetEdgeRequestByEdgeRequestIdDownloadCertificateApiResponse,
      GetEdgeRequestByEdgeRequestIdDownloadCertificateApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/download-certificate`,
      }),
    }),
    getEdgeRequestContainerRegistryConfiguration: build.query<
      GetEdgeRequestContainerRegistryConfigurationApiResponse,
      GetEdgeRequestContainerRegistryConfigurationApiArg
    >({
      query: () => ({ url: `/edge-request/container-registry-configuration` }),
    }),
    postEdgeRequestByEdgeRequestIdRestartWorkflow: build.mutation<
      PostEdgeRequestByEdgeRequestIdRestartWorkflowApiResponse,
      PostEdgeRequestByEdgeRequestIdRestartWorkflowApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/restart-workflow`,
        method: "POST",
      }),
    }),
    patchEdgeRequestByEdgeRequestIdConfirmMoveRegistration: build.mutation<
      PatchEdgeRequestByEdgeRequestIdConfirmMoveRegistrationApiResponse,
      PatchEdgeRequestByEdgeRequestIdConfirmMoveRegistrationApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/confirm-move-registration`,
        method: "PATCH",
      }),
    }),
    deleteEdgeRequestByEdgeRequestIdWithdraw: build.mutation<
      DeleteEdgeRequestByEdgeRequestIdWithdrawApiResponse,
      DeleteEdgeRequestByEdgeRequestIdWithdrawApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/withdraw`,
        method: "DELETE",
      }),
    }),
    deleteEdgeRequestByEdgeRequestIdMarkInvalid: build.mutation<
      DeleteEdgeRequestByEdgeRequestIdMarkInvalidApiResponse,
      DeleteEdgeRequestByEdgeRequestIdMarkInvalidApiArg
    >({
      query: (queryArg) => ({
        url: `/edge-request/${queryArg.edgeRequestId}/mark-invalid`,
        method: "DELETE",
        body: queryArg.edgeRequest,
      }),
    }),
    getHub: build.query<GetHubApiResponse, GetHubApiArg>({
      query: () => ({ url: `/hub` }),
    }),
    getHubByIotHubId: build.query<
      GetHubByIotHubIdApiResponse,
      GetHubByIotHubIdApiArg
    >({
      query: (queryArg) => ({ url: `/hub/${queryArg.iotHubId}` }),
    }),
    getHubByIotHubIdEdges: build.query<
      GetHubByIotHubIdEdgesApiResponse,
      GetHubByIotHubIdEdgesApiArg
    >({
      query: (queryArg) => ({
        url: `/hub/${queryArg.iotHubId}/edges`,
        params: {
          searchText: queryArg.searchText,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    getHubByIotHubIdSearchEdges: build.query<
      GetHubByIotHubIdSearchEdgesApiResponse,
      GetHubByIotHubIdSearchEdgesApiArg
    >({
      query: (queryArg) => ({
        url: `/hub/${queryArg.iotHubId}/search-edges`,
        params: { searchText: queryArg.searchText },
      }),
    }),
    getHubSiteBySiteIdEdges: build.query<
      GetHubSiteBySiteIdEdgesApiResponse,
      GetHubSiteBySiteIdEdgesApiArg
    >({
      query: (queryArg) => ({ url: `/hub/site/${queryArg.siteId}/edges` }),
    }),
    getModuleDefinition: build.query<
      GetModuleDefinitionApiResponse,
      GetModuleDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/module-definition`,
        params: { page: queryArg.page },
      }),
    }),
    postModuleDefinition: build.mutation<
      PostModuleDefinitionApiResponse,
      PostModuleDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/module-definition`,
        method: "POST",
        body: queryArg.definitionFile,
      }),
    }),
    getModuleDefinitionByModuleDefinitionId: build.query<
      GetModuleDefinitionByModuleDefinitionIdApiResponse,
      GetModuleDefinitionByModuleDefinitionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/module-definition/${queryArg.moduleDefinitionId}`,
      }),
    }),
    getModuleDefinitionByModuleDefinitionIdSchemaAndVersion: build.query<
      GetModuleDefinitionByModuleDefinitionIdSchemaAndVersionApiResponse,
      GetModuleDefinitionByModuleDefinitionIdSchemaAndVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/module-definition/${queryArg.moduleDefinitionId}/schema/${queryArg.version}`,
      }),
    }),
    postModuleDefinitionValidate: build.mutation<
      PostModuleDefinitionValidateApiResponse,
      PostModuleDefinitionValidateApiArg
    >({
      query: (queryArg) => ({
        url: `/module-definition/validate`,
        method: "POST",
        body: queryArg.definitionFileValidation,
      }),
    }),
    patchModuleDefinitionByModuleDefinitionIdArchive: build.mutation<
      PatchModuleDefinitionByModuleDefinitionIdArchiveApiResponse,
      PatchModuleDefinitionByModuleDefinitionIdArchiveApiArg
    >({
      query: (queryArg) => ({
        url: `/module-definition/${queryArg.moduleDefinitionId}/archive`,
        method: "PATCH",
      }),
    }),
    getNotification: build.query<
      GetNotificationApiResponse,
      GetNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/notification`,
        params: { page: queryArg.page },
      }),
    }),
    postNotification: build.mutation<
      PostNotificationApiResponse,
      PostNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/notification`,
        method: "POST",
        body: queryArg.notificationEmailAddress,
      }),
    }),
    getNotificationPurpose: build.query<
      GetNotificationPurposeApiResponse,
      GetNotificationPurposeApiArg
    >({
      query: () => ({ url: `/notification/purpose` }),
    }),
    deleteNotificationByEmailAddressAndPurpose: build.mutation<
      DeleteNotificationByEmailAddressAndPurposeApiResponse,
      DeleteNotificationByEmailAddressAndPurposeApiArg
    >({
      query: (queryArg) => ({
        url: `/notification/${queryArg.emailAddress}/${queryArg.purpose}`,
        method: "DELETE",
      }),
    }),
    getReportEdgesCountByRegion: build.query<
      GetReportEdgesCountByRegionApiResponse,
      GetReportEdgesCountByRegionApiArg
    >({
      query: () => ({ url: `/report/edges-count-by-region` }),
    }),
    getRoleDefinitionAllRoles: build.query<
      GetRoleDefinitionAllRolesApiResponse,
      GetRoleDefinitionAllRolesApiArg
    >({
      query: () => ({ url: `/role-definition/all-roles` }),
    }),
    getRoleDefinition: build.query<
      GetRoleDefinitionApiResponse,
      GetRoleDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/role-definition`,
        params: { page: queryArg.page },
      }),
    }),
    postRoleDefinition: build.mutation<
      PostRoleDefinitionApiResponse,
      PostRoleDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/role-definition`,
        method: "POST",
        body: queryArg.definitionFile,
      }),
    }),
    getRoleDefinitionByRoleNameSchema: build.query<
      GetRoleDefinitionByRoleNameSchemaApiResponse,
      GetRoleDefinitionByRoleNameSchemaApiArg
    >({
      query: (queryArg) => ({
        url: `/role-definition/${queryArg.roleName}/schema`,
      }),
    }),
    deleteRoleDefinitionByRoleName: build.mutation<
      DeleteRoleDefinitionByRoleNameApiResponse,
      DeleteRoleDefinitionByRoleNameApiArg
    >({
      query: (queryArg) => ({
        url: `/role-definition/${queryArg.roleName}`,
        method: "DELETE",
      }),
    }),
    postRoleDefinitionValidate: build.mutation<
      PostRoleDefinitionValidateApiResponse,
      PostRoleDefinitionValidateApiArg
    >({
      query: (queryArg) => ({
        url: `/role-definition/validate`,
        method: "POST",
        body: queryArg.definitionFileValidation,
      }),
    }),
    getRoleAssignment: build.query<
      GetRoleAssignmentApiResponse,
      GetRoleAssignmentApiArg
    >({
      query: (queryArg) => ({
        url: `/role-assignment`,
        params: { page: queryArg.page },
      }),
    }),
    postRoleAssignment: build.mutation<
      PostRoleAssignmentApiResponse,
      PostRoleAssignmentApiArg
    >({
      query: (queryArg) => ({
        url: `/role-assignment`,
        method: "POST",
        body: queryArg.userRole,
      }),
    }),
    getRoleAssignmentByRoleName: build.query<
      GetRoleAssignmentByRoleNameApiResponse,
      GetRoleAssignmentByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/role-assignment/${queryArg.roleName}` }),
    }),
    getRoleAssignmentLoggedInUser: build.query<
      GetRoleAssignmentLoggedInUserApiResponse,
      GetRoleAssignmentLoggedInUserApiArg
    >({
      query: () => ({ url: `/role-assignment/logged-in-user` }),
    }),
    deleteRoleAssignmentByUserUpnAndRoleName: build.mutation<
      DeleteRoleAssignmentByUserUpnAndRoleNameApiResponse,
      DeleteRoleAssignmentByUserUpnAndRoleNameApiArg
    >({
      query: (queryArg) => ({
        url: `/role-assignment/${queryArg.userUpn}/${queryArg.roleName}`,
        method: "DELETE",
      }),
    }),
    getWorkloadDefinition: build.query<
      GetWorkloadDefinitionApiResponse,
      GetWorkloadDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-definition`,
        params: { page: queryArg.page },
      }),
    }),
    postWorkloadDefinition: build.mutation<
      PostWorkloadDefinitionApiResponse,
      PostWorkloadDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-definition`,
        method: "POST",
        body: queryArg.definitionFile,
      }),
    }),
    getWorkloadDefinitionByWorkloadDefinitionId: build.query<
      GetWorkloadDefinitionByWorkloadDefinitionIdApiResponse,
      GetWorkloadDefinitionByWorkloadDefinitionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-definition/${queryArg.workloadDefinitionId}`,
      }),
    }),
    getWorkloadDefinitionByWorkloadDefinitionIdSchema: build.query<
      GetWorkloadDefinitionByWorkloadDefinitionIdSchemaApiResponse,
      GetWorkloadDefinitionByWorkloadDefinitionIdSchemaApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-definition/${queryArg.workloadDefinitionId}/schema`,
      }),
    }),
    getWorkloadDefinitionSearch: build.query<
      GetWorkloadDefinitionSearchApiResponse,
      GetWorkloadDefinitionSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-definition/search`,
        params: { searchText: queryArg.searchText },
      }),
    }),
    postWorkloadDefinitionValidate: build.mutation<
      PostWorkloadDefinitionValidateApiResponse,
      PostWorkloadDefinitionValidateApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-definition/validate`,
        method: "POST",
        body: queryArg.definitionFileValidation,
      }),
    }),
    patchWorkloadDefinitionByWorkloadDefinitionIdArchive: build.mutation<
      PatchWorkloadDefinitionByWorkloadDefinitionIdArchiveApiResponse,
      PatchWorkloadDefinitionByWorkloadDefinitionIdArchiveApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-definition/${queryArg.workloadDefinitionId}/archive`,
        method: "PATCH",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as edgeToolApi };
export type DeleteAdminCacheApiResponse = unknown;
export type DeleteAdminCacheApiArg = void;
export type GetAdminUsersApiResponse = /** status 200 OK */ User[];
export type GetAdminUsersApiArg = {
  /** Search for users whose email, name, surname starts with provided text.
    Search text should contain at least 4 characters. */
  searchText?: string;
};
export type GetAuditEventApiResponse = /** status 200 OK */ AuditEventList;
export type GetAuditEventApiArg = {
  page?: number;
};
export type GetAuthorizationAllowedOperationsApiResponse =
  /** status 200 OK */ string[];
export type GetAuthorizationAllowedOperationsApiArg = void;
export type GetConfigurationRegionsApiResponse = /** status 200 OK */ Region[];
export type GetConfigurationRegionsApiArg = void;
export type GetConfigurationFeaturesApiResponse = /** status 200 OK */ string[];
export type GetConfigurationFeaturesApiArg = void;
export type PostConfigurationSendTestEmailApiResponse =
  /** status 200 OK */ boolean;
export type PostConfigurationSendTestEmailApiArg = {
  beforeRenewal?: boolean;
};
export type GetDeploymentSecretGlobalApiResponse =
  /** status 200 OK */ DeploymentSecret[];
export type GetDeploymentSecretGlobalApiArg = void;
export type GetDeploymentSecretSiteApiResponse =
  /** status 200 OK */ DeploymentSecret[];
export type GetDeploymentSecretSiteApiArg = {
  siteId?: string;
};
export type GetDeploymentSecretDeviceApiResponse =
  /** status 200 OK */ DeploymentSecret[];
export type GetDeploymentSecretDeviceApiArg = {
  deviceId?: string;
};
export type PostDeploymentSecretApiResponse =
  /** status 200 OK */ DeploymentSecret;
export type PostDeploymentSecretApiArg = {
  deploymentSecret: DeploymentSecret;
};
export type PatchDeploymentSecretApiResponse =
  /** status 200 OK */ DeploymentSecret;
export type PatchDeploymentSecretApiArg = {
  deploymentSecret: DeploymentSecret;
};
export type DeleteDeploymentSecretApiResponse =
  /** status 200 OK */ DeploymentSecret;
export type DeleteDeploymentSecretApiArg = {
  deploymentSecret: DeploymentSecret;
};
export type GetEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse =
  /** status 200 OK */ Workload;
export type GetEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg = {
  edgeDeploymentId: string;
  workloadId: string;
};
export type PostEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse =
  /** status 200 OK */ void;
export type PostEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg = {
  edgeDeploymentId: string;
  workloadId: string;
  body: {
    [key: string]: JsonNode;
  };
};
export type PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse =
  /** status 200 OK */ void;
export type PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg = {
  edgeDeploymentId: string;
  workloadId: string;
  deploymentWorkloadInstance: DeploymentWorkloadInstance;
};
export type DeleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiResponse =
  /** status 200 OK */ EdgeDeployment;
export type DeleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdApiArg =
  {
    edgeDeploymentId: string;
    workloadId: string;
  };
export type PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatestApiResponse =
  /** status 200 OK */ Workload | /** status 204 No Content */ void;
export type PatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatestApiArg =
  {
    edgeDeploymentId: string;
    workloadId: string;
  };
export type GetDeviceCertificateInfoByDeviceIdApiResponse =
  /** status 200 OK */ DeviceCertificateInformation;
export type GetDeviceCertificateInfoByDeviceIdApiArg = {
  deviceId: string;
};
export type GetDeviceCertificateByDeviceIdApiResponse =
  /** status 200 OK */ DeviceCertificateInformation;
export type GetDeviceCertificateByDeviceIdApiArg = {
  deviceId: string;
};
export type GetDeviceCertificateByDeviceIdKeyApiResponse =
  /** status 200 OK */ DeviceCertificateInformation;
export type GetDeviceCertificateByDeviceIdKeyApiArg = {
  deviceId: string;
};
export type PostDeviceCertificateRenewApiResponse =
  /** status 200 OK */ DeviceCertificateInformation;
export type PostDeviceCertificateRenewApiArg = {
  deviceCertificateInformation: DeviceCertificateInformation;
};
export type GetDeviceCertificateIssuerchainpemApiResponse =
  /** status 200 OK */ IssuerCertificateInformation;
export type GetDeviceCertificateIssuerchainpemApiArg = void;
export type PostDeviceCertificateByCertificateNameExpiringApiResponse =
  /** status 200 OK */ DeviceCertificateInformation;
export type PostDeviceCertificateByCertificateNameExpiringApiArg = {
  certificateName: string;
};
export type GetEdgeByIotHubIdAndDeviceIdApiResponse =
  /** status 200 OK */ EdgeDevice;
export type GetEdgeByIotHubIdAndDeviceIdApiArg = {
  iotHubId: string;
  deviceId: string;
};
export type DeleteEdgeByIotHubIdAndDeviceIdApiResponse =
  /** status 200 OK */ EdgeRequest;
export type DeleteEdgeByIotHubIdAndDeviceIdApiArg = {
  iotHubId: string;
  deviceId: string;
};
export type GetEdgeByIotHubIdAndDeviceIdModuleIdApiResponse =
  /** status 200 OK */ string;
export type GetEdgeByIotHubIdAndDeviceIdModuleIdApiArg = {
  iotHubId: string;
  deviceId: string;
  moduleId: string;
};
export type PostEdgeByIotHubIdAndDeviceIdInstallCertificatesApiResponse =
  /** status 200 OK */ DeviceCertificateInformation;
export type PostEdgeByIotHubIdAndDeviceIdInstallCertificatesApiArg = {
  iotHubId: string;
  deviceId: string;
  deviceCertificateInformation: DeviceCertificateInformation;
};
export type PostEdgeByIotHubIdAndDeviceIdMoveEdgeRegistrationApiResponse =
  /** status 200 OK */ EdgeRequest;
export type PostEdgeByIotHubIdAndDeviceIdMoveEdgeRegistrationApiArg = {
  iotHubId: string;
  deviceId: string;
  moveRegistrationRequest: MoveRegistrationRequest;
};
export type GetEdgeDeploymentByDeploymentIdApiResponse =
  /** status 200 OK */ EdgeDeployment;
export type GetEdgeDeploymentByDeploymentIdApiArg = {
  deploymentId: string;
};
export type PatchEdgeDeploymentByDeploymentIdApiResponse =
  /** status 200 OK */ void;
export type PatchEdgeDeploymentByDeploymentIdApiArg = {
  deploymentId: string;
  edgeDeployment: EdgeDeployment;
};
export type DeleteEdgeDeploymentByDeploymentIdApiResponse =
  /** status 200 OK */ void;
export type DeleteEdgeDeploymentByDeploymentIdApiArg = {
  deploymentId: string;
};
export type GetEdgeDeploymentDraftByDeviceIdApiResponse =
  /** status 200 OK */ EdgeDeployment;
export type GetEdgeDeploymentDraftByDeviceIdApiArg = {
  deviceId: string;
};
export type GetEdgeDeploymentListApiResponse =
  /** status 200 OK */ EdgeDeploymentList;
export type GetEdgeDeploymentListApiArg = {
  page?: number;
  /** Parameter accepts comma separated edge deployment statuses */
  statuses?: string;
  deployer?: string;
  deviceIdSearchText?: string;
};
export type GetEdgeDeploymentByEdgeDeploymentIdConfigurationApiResponse =
  /** status 200 OK */ EdgeDeployment;
export type GetEdgeDeploymentByEdgeDeploymentIdConfigurationApiArg = {
  edgeDeploymentId: string;
};
export type PostEdgeDeploymentByEdgeDeploymentIdConfigurationApiResponse =
  unknown;
export type PostEdgeDeploymentByEdgeDeploymentIdConfigurationApiArg = {
  edgeDeploymentId: string;
};
export type PostEdgeDeploymentDraftApiResponse =
  /** status 200 OK */ EdgeDeployment;
export type PostEdgeDeploymentDraftApiArg = {
  edgeDeployment: EdgeDeployment;
};
export type PostEdgeDeploymentByEdgeDeploymentIdRefreshStatusApiResponse =
  unknown;
export type PostEdgeDeploymentByEdgeDeploymentIdRefreshStatusApiArg = {
  edgeDeploymentId: string;
};
export type PatchEdgeDeploymentByDeploymentIdSiteVariablesApiResponse =
  /** status 200 OK */ void;
export type PatchEdgeDeploymentByDeploymentIdSiteVariablesApiArg = {
  deploymentId: string;
  body: {
    [key: string]: JsonNode;
  };
};
export type GetEdgeRequestApiResponse = /** status 200 OK */ EdgeRequestList;
export type GetEdgeRequestApiArg = {
  page?: number;
  searchText?: string;
  requestor?: string;
  /** Parameter accepts comma separated edge request statuses */
  statuses?: string;
  engineerEmail?: string;
};
export type PostEdgeRequestApiResponse = /** status 200 OK */ EdgeRequest;
export type PostEdgeRequestApiArg = {
  edgeRequest: EdgeRequest;
};
export type GetEdgeRequestByEdgeRequestIdApiResponse =
  /** status 200 OK */ EdgeRequest;
export type GetEdgeRequestByEdgeRequestIdApiArg = {
  edgeRequestId: string;
};
export type PatchEdgeRequestByEdgeRequestIdApiResponse =
  /** status 204 No Content */ void;
export type PatchEdgeRequestByEdgeRequestIdApiArg = {
  edgeRequestId: string;
  edgeRequest: EdgeRequest;
};
export type GetEdgeRequestValidateDeviceidByDeviceIdApiResponse =
  /** status 200 OK */ DeviceIdValidation;
export type GetEdgeRequestValidateDeviceidByDeviceIdApiArg = {
  deviceId: string;
};
export type PatchEdgeRequestByEdgeRequestIdCompleteApiResponse =
  /** status 204 No Content */ void;
export type PatchEdgeRequestByEdgeRequestIdCompleteApiArg = {
  edgeRequestId: string;
};
export type PatchEdgeRequestByEdgeRequestIdRedeployApiResponse =
  /** status 204 No Content */ void;
export type PatchEdgeRequestByEdgeRequestIdRedeployApiArg = {
  edgeRequestId: string;
};
export type GetEdgeRequestByEdgeRequestIdConnectionStringApiResponse =
  /** status 200 OK */ EdgeDevice;
export type GetEdgeRequestByEdgeRequestIdConnectionStringApiArg = {
  edgeRequestId: string;
};
export type GetEdgeRequestByEdgeRequestIdDownloadCertificateApiResponse =
  /** status 200 OK */ DeviceCertificateInformation;
export type GetEdgeRequestByEdgeRequestIdDownloadCertificateApiArg = {
  edgeRequestId: string;
};
export type GetEdgeRequestContainerRegistryConfigurationApiResponse =
  /** status 200 OK */ RegistryConfiguration;
export type GetEdgeRequestContainerRegistryConfigurationApiArg = void;
export type PostEdgeRequestByEdgeRequestIdRestartWorkflowApiResponse =
  /** status 204 No Content */ void;
export type PostEdgeRequestByEdgeRequestIdRestartWorkflowApiArg = {
  edgeRequestId: string;
};
export type PatchEdgeRequestByEdgeRequestIdConfirmMoveRegistrationApiResponse =
  /** status 204 No Content */ void;
export type PatchEdgeRequestByEdgeRequestIdConfirmMoveRegistrationApiArg = {
  edgeRequestId: string;
};
export type DeleteEdgeRequestByEdgeRequestIdWithdrawApiResponse =
  /** status 204 No Content */ void;
export type DeleteEdgeRequestByEdgeRequestIdWithdrawApiArg = {
  edgeRequestId: string;
};
export type DeleteEdgeRequestByEdgeRequestIdMarkInvalidApiResponse =
  /** status 204 No Content */ void;
export type DeleteEdgeRequestByEdgeRequestIdMarkInvalidApiArg = {
  edgeRequestId: string;
  edgeRequest: EdgeRequest;
};
export type GetHubApiResponse = /** status 200 OK */ Hub[];
export type GetHubApiArg = void;
export type GetHubByIotHubIdApiResponse = /** status 200 OK */ Hub;
export type GetHubByIotHubIdApiArg = {
  iotHubId: string;
};
export type GetHubByIotHubIdEdgesApiResponse = /** status 200 OK */ EdgeList;
export type GetHubByIotHubIdEdgesApiArg = {
  iotHubId: string;
  searchText?: string;
  pageNumber?: number;
};
export type GetHubByIotHubIdSearchEdgesApiResponse =
  /** status 200 OK */ EdgeDevice[];
export type GetHubByIotHubIdSearchEdgesApiArg = {
  /** IoT hub on which devices will be searched. */
  iotHubId: string;
  /** Search for edge devices with device id that starts with provided text.
    Search text should contain at least 10 characters. */
  searchText?: string;
};
export type GetHubSiteBySiteIdEdgesApiResponse =
  /** status 200 OK */ EdgeDevice[];
export type GetHubSiteBySiteIdEdgesApiArg = {
  siteId: string;
};
export type GetModuleDefinitionApiResponse =
  /** status 200 OK */ ModuleDefinitionList;
export type GetModuleDefinitionApiArg = {
  page?: number;
};
export type PostModuleDefinitionApiResponse =
  /** status 200 OK */ ModuleDefinition;
export type PostModuleDefinitionApiArg = {
  definitionFile: DefinitionFile;
};
export type GetModuleDefinitionByModuleDefinitionIdApiResponse =
  /** status 200 OK */ ModuleDefinition[];
export type GetModuleDefinitionByModuleDefinitionIdApiArg = {
  moduleDefinitionId: string;
};
export type GetModuleDefinitionByModuleDefinitionIdSchemaAndVersionApiResponse =
  /** status 200 OK */ ModuleDefinitionSchema;
export type GetModuleDefinitionByModuleDefinitionIdSchemaAndVersionApiArg = {
  moduleDefinitionId: string;
  version: string;
};
export type PostModuleDefinitionValidateApiResponse =
  /** status 200 OK */ DefinitionFileValidation;
export type PostModuleDefinitionValidateApiArg = {
  definitionFileValidation: DefinitionFileValidation;
};
export type PatchModuleDefinitionByModuleDefinitionIdArchiveApiResponse =
  /** status 200 OK */ boolean;
export type PatchModuleDefinitionByModuleDefinitionIdArchiveApiArg = {
  moduleDefinitionId: string;
};
export type GetNotificationApiResponse = /** status 200 OK */ NotificationList;
export type GetNotificationApiArg = {
  page?: number;
};
export type PostNotificationApiResponse = /** status 204 No Content */ void;
export type PostNotificationApiArg = {
  notificationEmailAddress: NotificationEmailAddress;
};
export type GetNotificationPurposeApiResponse = /** status 200 OK */ string[];
export type GetNotificationPurposeApiArg = void;
export type DeleteNotificationByEmailAddressAndPurposeApiResponse =
  /** status 204 No Content */ void;
export type DeleteNotificationByEmailAddressAndPurposeApiArg = {
  emailAddress: string;
  purpose: string;
};
export type GetReportEdgesCountByRegionApiResponse =
  /** status 200 OK */ EdgesCountPerRegion[];
export type GetReportEdgesCountByRegionApiArg = void;
export type GetRoleDefinitionAllRolesApiResponse = /** status 200 OK */ Role[];
export type GetRoleDefinitionAllRolesApiArg = void;
export type GetRoleDefinitionApiResponse = /** status 200 OK */ RoleList;
export type GetRoleDefinitionApiArg = {
  page?: number;
};
export type PostRoleDefinitionApiResponse = /** status 200 OK */ Role;
export type PostRoleDefinitionApiArg = {
  definitionFile: DefinitionFile;
};
export type GetRoleDefinitionByRoleNameSchemaApiResponse =
  /** status 200 OK */ RoleDefinitionSchema;
export type GetRoleDefinitionByRoleNameSchemaApiArg = {
  roleName: string;
};
export type DeleteRoleDefinitionByRoleNameApiResponse =
  /** status 204 No Content */ void;
export type DeleteRoleDefinitionByRoleNameApiArg = {
  roleName: string;
};
export type PostRoleDefinitionValidateApiResponse =
  /** status 200 OK */ DefinitionFileValidation;
export type PostRoleDefinitionValidateApiArg = {
  definitionFileValidation: DefinitionFileValidation;
};
export type GetRoleAssignmentApiResponse = /** status 200 OK */ UserRoleList;
export type GetRoleAssignmentApiArg = {
  page?: number;
};
export type PostRoleAssignmentApiResponse = /** status 204 No Content */ void;
export type PostRoleAssignmentApiArg = {
  userRole: UserRole;
};
export type GetRoleAssignmentByRoleNameApiResponse =
  /** status 200 OK */ UserRole[];
export type GetRoleAssignmentByRoleNameApiArg = {
  roleName: string;
};
export type GetRoleAssignmentLoggedInUserApiResponse =
  /** status 200 OK */ UserRole[];
export type GetRoleAssignmentLoggedInUserApiArg = void;
export type DeleteRoleAssignmentByUserUpnAndRoleNameApiResponse =
  /** status 204 No Content */ void;
export type DeleteRoleAssignmentByUserUpnAndRoleNameApiArg = {
  userUpn: string;
  roleName: string;
};
export type GetWorkloadDefinitionApiResponse =
  /** status 200 OK */ WorkloadList;
export type GetWorkloadDefinitionApiArg = {
  page?: number;
};
export type PostWorkloadDefinitionApiResponse = /** status 200 OK */ Workload;
export type PostWorkloadDefinitionApiArg = {
  definitionFile: DefinitionFile;
};
export type GetWorkloadDefinitionByWorkloadDefinitionIdApiResponse =
  /** status 200 OK */ Workload;
export type GetWorkloadDefinitionByWorkloadDefinitionIdApiArg = {
  workloadDefinitionId: string;
};
export type GetWorkloadDefinitionByWorkloadDefinitionIdSchemaApiResponse =
  /** status 200 OK */ WorkloadDefinitionSchema;
export type GetWorkloadDefinitionByWorkloadDefinitionIdSchemaApiArg = {
  workloadDefinitionId: string;
};
export type GetWorkloadDefinitionSearchApiResponse =
  /** status 200 OK */ Workload[];
export type GetWorkloadDefinitionSearchApiArg = {
  searchText?: string;
};
export type PostWorkloadDefinitionValidateApiResponse =
  /** status 200 OK */ DefinitionFileValidation;
export type PostWorkloadDefinitionValidateApiArg = {
  definitionFileValidation: DefinitionFileValidation;
};
export type PatchWorkloadDefinitionByWorkloadDefinitionIdArchiveApiResponse =
  unknown;
export type PatchWorkloadDefinitionByWorkloadDefinitionIdArchiveApiArg = {
  workloadDefinitionId: string;
};
export type User = {
  displayName?: string | null;
  mail?: string | null;
  upn?: string | null;
  oid?: string | null;
};
export type AuditEvent = {
  eventType?: string | null;
  action?: string | null;
  actionedBy?: string | null;
  timeStamp?: string;
};
export type AuditEventList = {
  auditEvents?: AuditEvent[] | null;
  totalItems?: number;
  pageNumber?: number;
  hasMoreData?: boolean;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type Region = {
  regionName?: string | null;
  iotHubIds?: string[] | null;
};
export type SecretScope = "Global" | "Site" | "Device";
export type DeploymentSecret = {
  secretId?: string | null;
  scope?: SecretScope;
  siteId?: string | null;
  deviceId?: string | null;
  value?: string | null;
};
export type ModuleWorkload = {
  name?: string | null;
  latestModuleWorkload?: boolean;
};
export type ModuleDefinition = {
  id?: number;
  moduleId?: string | null;
  name?: string | null;
  version?: string | null;
  released?: string | null;
  archived?: boolean;
  modulesWorkloadList?: ModuleWorkload[] | null;
};
export type SchemaVariable = {
  variable?: string | null;
  type?: string | null;
  value?: string | null;
  description?: string | null;
  path?: string | null;
};
export type Workload = {
  id?: number;
  workloadId?: string | null;
  name?: string | null;
  updated?: string | null;
  moduleDefinitions?: ModuleDefinition[] | null;
  workloadVariables?: SchemaVariable[] | null;
  outOfDate?: boolean;
  deploymentWorkloadOrder?: number;
  hasEmptyVariables?: boolean;
  hasArchivedModules?: boolean;
  archived?: boolean;
  canArchive?: boolean;
};
export type JsonNodeOptions = {
  propertyNameCaseInsensitive?: boolean;
};
export type JsonNode = {
  options?: JsonNodeOptions;
  parent?: JsonNode;
  root?: JsonNode;
};
export type DeploymentWorkloadInstance = {
  workloadVariables?: string | null;
  workloadOrder?: number | null;
};
export type EdgeDeploymentStatus = "Draft" | "Deployed" | "Failed" | "Running";
export type EdgeDeployment = {
  edgeDeploymentId?: string | null;
  iotHubId?: string | null;
  deviceId?: string | null;
  createdBy?: string | null;
  status?: EdgeDeploymentStatus;
  timestamp?: string | null;
  revision?: string | null;
  countPerDevice?: number | null;
  deploymentManifest?: string | null;
  deployedBy?: string | null;
  siteId?: string | null;
  workloads?: Workload[] | null;
  siteVariables?: SchemaVariable[] | null;
};
export type DeviceCertificateInformation = {
  iotHubId?: string | null;
  deviceId?: string | null;
  notBefore?: string | null;
  notAfter?: string | null;
  certificatePEM?: string | null;
  certificateKeyPEM?: string | null;
  certificateFullChainPEM?: string | null;
};
export type IssuerCertificateInformation = {
  issuerCertificateChainPEM?: string | null;
};
export type DeviceFunction = "Edge" | "Gateway";
export type ModuleStatus = {
  moduleId?: string | null;
  desiredStatus?: string | null;
  reportedStatus?: string | null;
  desiredImage?: string | null;
  reportedImage?: string | null;
  reportedVersion?: string | null;
};
export type EdgeStatusCode =
  | "Indetermined"
  | "AppliedAndSuccessful"
  | "EdgeAgentNotRunning"
  | "EmptyDeployment"
  | "InvalidDeployment"
  | "InProgress"
  | "FailedModule"
  | "IncorrectImage";
export type EdgeStatus = {
  desiredVersion?: number;
  lastDesiredVersion?: number | null;
  lastDesiredStatusCode?: number | null;
  lastDesiredStatusDescription?: string | null;
  edgeAgentStatus?: ModuleStatus;
  edgeHubStatus?: ModuleStatus;
  moduleStatuses?: ModuleStatus[] | null;
  statusCode?: EdgeStatusCode;
  failedModules?: string[] | null;
};
export type EdgeDevice = {
  deviceId?: string | null;
  function?: DeviceFunction;
  deviceScope?: string | null;
  parentId?: string | null;
  iotHubId?: string | null;
  siteId?: string | null;
  frameworkVersion?: string | null;
  region?: string | null;
  status?: EdgeStatus;
  connectionString?: string | null;
  certificateNotBefore?: string | null;
  certificateNotAfter?: string | null;
};
export type EdgeRequestStatus =
  | "New"
  | "Requested"
  | "RequestedEdgeDeviceCreated"
  | "RecoverRequestedCertificate"
  | "RequestedCertificatesCreated"
  | "InProgress"
  | "WithdrawRequested"
  | "WithdrawEdgeDeviceRemoved"
  | "Withdrawn"
  | "InvalidationRequested"
  | "InvalidationEdgeDeviceRemoved"
  | "InvalidationEdgeDeviceDeploymentsRemoved"
  | "Invalid"
  | "Completed"
  | "DecommissionRequested"
  | "DecommissionEdgeDeviceRemoved"
  | "Decommissioned"
  | "MoveRegistrationRequested"
  | "MoveRegistrationConfirmationRequired"
  | "MoveRegistrationConfirmed"
  | "MoveRegistrationEdgeDeviceRemoved"
  | "MoveRegistrationCompleted";
export type EdgeRequest = {
  edgeRequestId?: string | null;
  timestamp?: string | null;
  regionName?: string | null;
  deviceId?: string | null;
  iotHubId?: string | null;
  formFactor?: string | null;
  engineerEmail?: string | null;
  status?: EdgeRequestStatus;
  ownerEmail?: string | null;
  siteId?: string | null;
  newIoTHubId?: string | null;
  invalidReason?: string | null;
  stateReason?: string | null;
  function?: DeviceFunction;
  parentDeviceId?: string | null;
};
export type MoveRegistrationRequest = {
  newIoTHubId?: string | null;
  parentId?: string | null;
};
export type EdgeDeploymentList = {
  edgeDeployments?: EdgeDeployment[] | null;
  totalItems?: number;
  pageNumber?: number;
  hasMoreData?: boolean;
  deviceIdSearchText?: string | null;
  status?: string | null;
};
export type EdgeRequestList = {
  edgeRequests?: EdgeRequest[] | null;
  totalItems?: number;
  pageNumber?: number;
  hasMoreData?: boolean;
  searchText?: string | null;
  status?: string | null;
};
export type DeviceIdValidation = {
  validDeviceId?: boolean;
  deviceIdErrors?: string[] | null;
};
export type RegistryConfiguration = {
  id?: number | null;
  address?: string | null;
  username?: string | null;
  password?: string | null;
};
export type Hub = {
  iotHubId: string;
  regionName?: string | null;
  edgeDevices?: EdgeDevice[] | null;
};
export type EdgeList = {
  edgeDevices?: EdgeDevice[] | null;
  pageNumber?: number;
  searchText?: string | null;
  hasMoreData?: boolean;
};
export type ModuleDefinitionList = {
  moduleDefinitions?: ModuleDefinition[] | null;
  pageNumber?: number;
  totalItems?: number;
  hasMoreData?: boolean;
};
export type DefinitionFile = {
  content?: string | null;
};
export type ModuleDefinitionSchema = {
  id?: string | null;
  name?: string | null;
  version?: string | null;
  schema?: string | null;
  template?: string | null;
};
export type DefinitionFileValidation = {
  content?: string | null;
  isValid?: boolean | null;
  errors?: string[] | null;
};
export type NotificationPurpose =
  | "ExpiringCertificate"
  | "SecretRotationVerification";
export type NotificationEmailAddress = {
  id?: number | null;
  purpose?: NotificationPurpose;
  emailAddress?: string | null;
};
export type NotificationList = {
  notificationEmailAddresses?: NotificationEmailAddress[] | null;
  totalItems?: number;
  pageNumber?: number;
  hasMoreData?: boolean;
};
export type EdgesCountPerRegion = {
  regionName?: string | null;
  edgesCount?: number | null;
};
export type RoleAction = {
  actionName?: string | null;
  isAction?: boolean;
};
export type Role = {
  name?: string | null;
  roleActions?: RoleAction[] | null;
};
export type RoleList = {
  roles?: Role[] | null;
  pageNumber?: number;
  totalItems?: number;
  hasMoreData?: boolean;
};
export type RoleDefinitionSchema = {
  name?: string | null;
  actions?: string[] | null;
  notActions?: string[] | null;
};
export type UserRole = {
  id?: number | null;
  userUpn?: string | null;
  userOid?: string | null;
  roleName?: string | null;
};
export type UserRoleList = {
  userRoles?: UserRole[] | null;
  totalItems?: number;
  pageNumber?: number;
  hasMoreData?: boolean;
  administratorRolesCount?: number;
};
export type WorkloadList = {
  workloads?: Workload[] | null;
  totalItems?: number;
  pageNumber?: number;
  hasMoreData?: boolean;
};
export type WorkloadDefinitionSchemaModule = {
  id?: string | null;
  version?: string | null;
  variables?: string | null;
};
export type WorkloadDefinitionSchema = {
  id?: string | null;
  name?: string | null;
  modules?: WorkloadDefinitionSchemaModule[] | null;
  schema?: string | null;
  template?: string | null;
};
export const {
  useDeleteAdminCacheMutation,
  useGetAdminUsersQuery,
  useGetAuditEventQuery,
  useGetAuthorizationAllowedOperationsQuery,
  useGetConfigurationRegionsQuery,
  useGetConfigurationFeaturesQuery,
  usePostConfigurationSendTestEmailMutation,
  useGetDeploymentSecretGlobalQuery,
  useGetDeploymentSecretSiteQuery,
  useGetDeploymentSecretDeviceQuery,
  usePostDeploymentSecretMutation,
  usePatchDeploymentSecretMutation,
  useDeleteDeploymentSecretMutation,
  useGetEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdQuery,
  usePostEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdMutation,
  usePatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdMutation,
  useDeleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdMutation,
  usePatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatestMutation,
  useGetDeviceCertificateInfoByDeviceIdQuery,
  useGetDeviceCertificateByDeviceIdQuery,
  useGetDeviceCertificateByDeviceIdKeyQuery,
  usePostDeviceCertificateRenewMutation,
  useGetDeviceCertificateIssuerchainpemQuery,
  usePostDeviceCertificateByCertificateNameExpiringMutation,
  useGetEdgeByIotHubIdAndDeviceIdQuery,
  useDeleteEdgeByIotHubIdAndDeviceIdMutation,
  useGetEdgeByIotHubIdAndDeviceIdModuleIdQuery,
  usePostEdgeByIotHubIdAndDeviceIdInstallCertificatesMutation,
  usePostEdgeByIotHubIdAndDeviceIdMoveEdgeRegistrationMutation,
  useGetEdgeDeploymentByDeploymentIdQuery,
  usePatchEdgeDeploymentByDeploymentIdMutation,
  useDeleteEdgeDeploymentByDeploymentIdMutation,
  useGetEdgeDeploymentDraftByDeviceIdQuery,
  useGetEdgeDeploymentListQuery,
  useGetEdgeDeploymentByEdgeDeploymentIdConfigurationQuery,
  usePostEdgeDeploymentByEdgeDeploymentIdConfigurationMutation,
  usePostEdgeDeploymentDraftMutation,
  usePostEdgeDeploymentByEdgeDeploymentIdRefreshStatusMutation,
  usePatchEdgeDeploymentByDeploymentIdSiteVariablesMutation,
  useGetEdgeRequestQuery,
  usePostEdgeRequestMutation,
  useGetEdgeRequestByEdgeRequestIdQuery,
  usePatchEdgeRequestByEdgeRequestIdMutation,
  useGetEdgeRequestValidateDeviceidByDeviceIdQuery,
  usePatchEdgeRequestByEdgeRequestIdCompleteMutation,
  usePatchEdgeRequestByEdgeRequestIdRedeployMutation,
  useGetEdgeRequestByEdgeRequestIdConnectionStringQuery,
  useGetEdgeRequestByEdgeRequestIdDownloadCertificateQuery,
  useGetEdgeRequestContainerRegistryConfigurationQuery,
  usePostEdgeRequestByEdgeRequestIdRestartWorkflowMutation,
  usePatchEdgeRequestByEdgeRequestIdConfirmMoveRegistrationMutation,
  useDeleteEdgeRequestByEdgeRequestIdWithdrawMutation,
  useDeleteEdgeRequestByEdgeRequestIdMarkInvalidMutation,
  useGetHubQuery,
  useGetHubByIotHubIdQuery,
  useGetHubByIotHubIdEdgesQuery,
  useGetHubByIotHubIdSearchEdgesQuery,
  useGetHubSiteBySiteIdEdgesQuery,
  useGetModuleDefinitionQuery,
  usePostModuleDefinitionMutation,
  useGetModuleDefinitionByModuleDefinitionIdQuery,
  useGetModuleDefinitionByModuleDefinitionIdSchemaAndVersionQuery,
  usePostModuleDefinitionValidateMutation,
  usePatchModuleDefinitionByModuleDefinitionIdArchiveMutation,
  useGetNotificationQuery,
  usePostNotificationMutation,
  useGetNotificationPurposeQuery,
  useDeleteNotificationByEmailAddressAndPurposeMutation,
  useGetReportEdgesCountByRegionQuery,
  useGetRoleDefinitionAllRolesQuery,
  useGetRoleDefinitionQuery,
  usePostRoleDefinitionMutation,
  useGetRoleDefinitionByRoleNameSchemaQuery,
  useDeleteRoleDefinitionByRoleNameMutation,
  usePostRoleDefinitionValidateMutation,
  useGetRoleAssignmentQuery,
  usePostRoleAssignmentMutation,
  useGetRoleAssignmentByRoleNameQuery,
  useGetRoleAssignmentLoggedInUserQuery,
  useDeleteRoleAssignmentByUserUpnAndRoleNameMutation,
  useGetWorkloadDefinitionQuery,
  usePostWorkloadDefinitionMutation,
  useGetWorkloadDefinitionByWorkloadDefinitionIdQuery,
  useGetWorkloadDefinitionByWorkloadDefinitionIdSchemaQuery,
  useGetWorkloadDefinitionSearchQuery,
  usePostWorkloadDefinitionValidateMutation,
  usePatchWorkloadDefinitionByWorkloadDefinitionIdArchiveMutation,
} = injectedRtkApi;
