import { Button, MessageBar, MessageBarActions, MessageBarBody } from "@fluentui/react-components";
import { hideMessageBar, selectMessageBarState } from "../../app/messageBarSlice";
import { DismissRegular } from "@fluentui/react-icons";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";

export const PageMessageBar = () => {
    const dispatch = useDispatch();
    const state = useAppSelector(selectMessageBarState);
    if (!state.visible) {
        return <></>;
    }

    return (
        <MessageBar intent={state.type} layout="multiline">
            <MessageBarBody>
                {state.message}
            </MessageBarBody>
            <MessageBarActions
                containerAction={
                    <Button appearance="transparent" icon={<DismissRegular />} onClick={() => dispatch(hideMessageBar())}/>
                }
            />
        </MessageBar>
    );
}
