import { useSyncExternalStore } from "react"

export const useIsMobile = () => {
    const isMobile = useSyncExternalStore(subscribe, getSnapshot);
    return isMobile;
}

function getSnapshot() {
    return window.innerWidth <= 750;
}

function subscribe(updateDimensions: any) {
    window.addEventListener("resize", updateDimensions);
    window.addEventListener("load", updateDimensions);
    return () => {
        window.removeEventListener("load", updateDimensions);
        window.removeEventListener("resize", updateDimensions);
    };
}