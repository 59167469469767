import React, { useEffect } from "react";
import { setFeatures } from "../../app/featureSlice";
import { showMessageBar } from "../../app/messageBarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useGetConfigurationFeaturesQuery } from "../../services/enhancedEdgeToolApi";

export const FeatureProvider = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();
    const { data, isLoading, isError } = useGetConfigurationFeaturesQuery();

    useEffect(() => {
        dispatch(setFeatures(isLoading || isError ?
            { features: undefined, isLoadingEnabledFeatures: isError ? false : isLoading } :
            { features: data, isLoadingEnabledFeatures: isLoading }));

        if (isError) {
            dispatch(showMessageBar({ message: "Could not determine enabled application features", type: "error" }));
        }
    }, [dispatch, isLoading, isError, data]);

    return <>{children}</>;
};
