import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { initializeIcons, mergeStyles } from '@fluentui/react';
import { App } from './App';
import { AuthorizedOperationProvider } from './components/AuthorizedOperationProvider/AuthorizedOperationProvider';
import { EnsureLogin } from './auth/components/EnsureLogin';
import { FeatureProvider } from './components/FeatureProvider/FeatureProvider';
import { LoginPage } from './pages/LoginPage';
import { MsalProvider } from '@azure/msal-react';
import { NotLoggedIn } from './auth/components/NotLoggedIn';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { msalInstance } from './auth/msal';
import reportWebVitals from './reportWebVitals';
import { setupStore } from './app/store';

initializeIcons();

const store = setupStore();

// Inject some global styles
mergeStyles({
    ':global(body,html,#root)': {
        margin: 0,
        padding: 0,
        height: '100vh',
    }
});

createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <Router>

            <Provider store={store}>
                <FluentProvider theme={webLightTheme}>
                    <MsalProvider instance={msalInstance}>
                        <EnsureLogin app={msalInstance}>
                            <AuthorizedOperationProvider>
                                <FeatureProvider>
                                    <App />
                                </FeatureProvider>
                            </AuthorizedOperationProvider>
                        </EnsureLogin>
                        <NotLoggedIn>
                            <LoginPage />
                        </NotLoggedIn>
                    </MsalProvider>
                </FluentProvider>
            </Provider>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
