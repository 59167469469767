import { InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { selectAccountInfo, signInAction } from "../../app/accountSlice";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CustomNavigationClient } from '../navigationClient';
import React from 'react';
import { redirectLoginRequest } from '../authConfig';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useStaticStyles } from '../../MainStyles';

export const EnsureLogin = ({ app, children }: { app: PublicClientApplication, children: React.ReactNode }) => {
    // Connect MSAL navigation to React Router navigation -- needs MsalProvider as a parent
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    app.setNavigationClient(navigationClient);
    useStaticStyles();

    // Ensure we are logged in.
    //
    // When changing this code, please note that a lot of things are cached in browser storage and cookies.
    // Use at least the following test cases when changing this code:
    // 1. Regular browser while logged in in another window/tab
    // 2. *NEW* private window (all other private windows closed first!)
    // 3. Second private window while logged in in another private window/tab
    //
    const dispatch = useAppDispatch();
    const accountSlice = useAppSelector(selectAccountInfo);
    const { instance, inProgress, accounts } = useMsal();

    useEffect(() => {
        if (accounts.length === 0 && inProgress === InteractionStatus.None) {
            instance.loginRedirect(redirectLoginRequest);
        } else if (!instance.getActiveAccount() && inProgress === InteractionStatus.None) {
            instance.setActiveAccount(accounts[0]);
            dispatch(signInAction(accounts[0]));
        } else if (!accountSlice.graphData && inProgress === InteractionStatus.None) {
            dispatch(signInAction(instance.getActiveAccount()!));
        }
    }, [instance, inProgress, accounts, dispatch, accountSlice.graphData]);

    if (accountSlice.status !== 'loggedIn') {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
}
