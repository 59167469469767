import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from "./authConfig";

// TODO: add tests for MSAL, see
//  https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/test/components/MsalAuthenticationTemplate.spec.tsx
// for inspiration

export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();
